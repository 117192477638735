import React from "react"
import Layout from "../components/layout/Layout.js"
import { Column100 } from "../components/column/Column.js"
import { Row } from "../components/row/Row.js"
import { TextMedium, TextBig } from "../components/text/Text"
import { Title, BlogTitle } from "../components/title/Title"
import { List } from "../components/list/List"
import Seo from "../components/seo"
import { AboveFold, Section } from "../components/section/Section.js"
import { BlogRow } from "../components/row/Row.js"


function Cookies ({ children }) {
  return (
<Layout>
    <Seo title="Cookies" />
    <Section>
      <BlogRow>
        <BlogTitle isPrimary title="Cookies" />
        <TextMedium
          textMedium="Cookies are pieces of code installed in your browser that assist us in 
        providing you with content, according to our terms and as per your consent. The installation of cookies 
        is based on your consent, and you can always freely withdraw the consent by following the steps 
        described here."
        />
        <TextMedium textMedium="How to provide or withdraw consent to the installation of cookies." />
        <TextMedium
          textMedium="You can manage preferences for cookies - including deleting the ones installed in the past - directly 
        from within your browser. For cookies installed by third parties, you can manage your preferences and withdraw your consent by clicking the 
        related opt-out link (if provided), by using the means provided in the third party's privacy policy, or by contacting the third party."
        />
        <TextMedium
          textMedium="For this website, the Owner and Data Controller is: School of Content, Spoorstraat 28, 1815BK, Alkmaar, 
        The Netherlands."
        />
        <TextMedium textMedium="Contact e-mail: contact@schoolofcontent.net." />
        <TextMedium
          textMedium="Since the installation of third-party cookies and other tracking systems through the services used within our website
         cannot be technically controlled by the Owner, any specific references to cookies and tracking systems installed by third parties are to 
         be considered indicative. In order to obtain complete information, please consult the privacy policy for the respective third-party services. 
         If you want to receive any further information on the use of cookies by this website, please contact the Owner."
        />
        <TextBig textBig="Types of cookies used by this website" />
        <TextMedium
          textMedium="We use session cookies, which expire after a
        short time or when you close your browser, and persistent cookies, which
        remain stored in your browser for a set period of time."
        />
        <List>
          Technical cookies
          <br />
          <br />
          <li>
            This website uses cookies to save the user's session and to carry
            out other activities that are strictly necessary for the operation
            of this website, for example in relation to the distribution of
            traffic. This website uses cookies to save browsing preferences and
            to optimize the user's browsing experience.
          </li>
          <br />
          <br />
          <li>
            Some of the services listed below collect statistics in an
            anonymized and aggregated form and may not require the user's
            consent, or may be managed directly by us. If any third party
            operated services are listed among the tools below, these may be
            used to track the user's browsing habits – in addition to the
            information specified herein and without our knowledge. Please refer
            to our Privacy policy for detailed information.
          </li>
          <br />
          <br />
          <li>
            Authentication and security
            <br />
            <br />
            This type of cookies helps us detect and fight spam and protect your
            security. They also help to remember data about your browser and
            preferences, such as settings and privacy choices you've made, your
            preferred language and so on.
          </li>
          <br />
          <br />
          <li>
            Advertising cookies
            <br />
            <br />
            This type of service allows user data to be utilized for advertising
            purposes. Please note that although we don't display ads on this
            website, you may still receive advertising on other websites and
            applications that use advertising technology. To learn more about
            this and to opt out, visit the Your Online Choices site, or if
            you're located outside of the European Union, visit
            www.allaboutcookies.org/manage-cookies/index.html.
            <br />
            <br />
            Some of the services listed below may use cookies to identify users
            or they may use the behavioral retargeting technique, i.e.
            displaying ads tailored to the user’s interests and behavior,
            including those detected outside this website. For more information,
            please check the Privacy policies of these services.
          </li>
          <br />
          <br />
          <li>
            Google Ad Manager cookies
            <br />
            <br />
            These cookies allow the Owner to run advertising campaigns in
            conjunction with external advertising networks that the Owner,
            unless otherwise specified in this document, has no direct
            relationship with. In order to opt out from being tracked by various
            advertising networks, users may make use of Youronlinechoices. In
            order to understand Google's use of data, consult Google's partner
            policy. Users may decide to disable all cookies by going to Google
            Ad Settings.
          </li>
          <br />
          <br />
          <li>
            HubSpot Analytics
            <br />
            <br />
            These cookies allow the Owner to collect data regarding visitors of
            this website who have not yet converted on a form. These may include
            essential cookies which don't require consent, and consent banner
            cookies, which are controlled by the consent banner. For more
            information, please consult HubSpot's policy.
          </li>
          <br />
          <br />
          <li>
            Google Analytics
            <br />
            <br />
            Google utilizes the data collected to track and examine the use of
            this website, to prepare reports on its activities and share them
            with other Google services. Google may use the data collected to
            contextualize and personalize the ads of its own advertising
            network. Users can opt out of Google's use of cookies by visiting
            Google's Ads Settings or the Google Analytics Opt-out Browser
            Add-on.
          </li>
        </List>
        <TextBig textBig="Definitions and terms used on this page" />
        <List>
          <li>
            Personal data or data = Any information that directly, indirectly,
            or in connection with other information — including a personal
            identification number — allows for the identification or
            identifiability of a natural person.
          </li>
          <br />
          <li>
            Usage data = Information collected automatically through this
            website (or third-party services employed in this website), which
            can include: the IP addresses or domain names of the computers
            utilized by the users who use this website, the URI addresses
            (Uniform Resource Identifier), the time of the request, the method
            utilized to submit the request to the server, the size of the file
            received in response, the numerical code indicating the status of
            the server's answer (successful outcome, error, etc.), the country
            of origin, the features of the browser and the operating system
            utilized by the User, the various time details per visit (e.g., the
            time spent on each page within the Application) and the details
            about the path followed within the Application with special
            reference to the sequence of pages visited, and other parameters
            about the device operating system and/or the User's IT environment.
          </li>
          <br />
          <li>
            User = The individual using this website who, unless otherwise
            specified, coincides with the Data Subject.
          </li>
          <br />
          <li>
            Data Subject = The natural person to whom the Personal Data refers.
          </li>
          <br />
          <li>
            Data Processor (or Data Supervisor) = The natural or legal person,
            public authority, agency or other body which processes Personal Data
            on behalf of the Controller, as described in this privacy policy.
          </li>
          <br />
          <li>
            Data Controller (or Owner) = The natural or legal person, public
            authority, agency or other body which, alone or jointly with others,
            determines the purposes and means of the processing of Personal
            Data, including the security measures concerning the operation and
            use of this Website. The Data Controller, unless otherwise
            specified, is the Owner of this Website.
          </li>
          <br />
          <li>
            This Website (or this Application) = The means by which the Personal
            Data of the User is collected and processed.{" "}
          </li>
          <br />
          <li>
            Service = The service provided by this Website as described in the
            relative terms (if available) and on this site/application.
          </li>
          <br />
          <li>Cookies = Small sets of data stored in the User's device.</li>
          <br />
          <li>
            Legal information = Notice to European Users: this privacy statement
            has been prepared in fulfillment of the obligations under Art. 10 of
            EC Directive n. 95/46/EC, and under the provisions of Directive
            2002/58/EC, as revised by Directive 2009/136/EC, on the subject of
            Cookies.
          </li>
        </List>
        <TextMedium textMedium="Updated and effective: August 26, 2022" />
      </BlogRow>
    </Section>
  </Layout>
)}

export default Cookies