import styled from "styled-components"
import { colors } from "../../styles/colors.js"
import { sizes } from "../../styles/sizes.js"

export const StyledList = styled.ul`
  list-style: none;
  margin-top: ${sizes.sizeL};
  margin-left: ${props => (props.isCentered? `auto` : ``)};
  margin-right: ${props => (props.isCentered? `auto` : ``)};
  transition: all .5s cubic-bezier(0,.89,.44,1);
  color: ${colors.colorTextPurple};

  a {
    color: ${colors.colorContentDark};
    text-decoration: none;
  }
`
